import React from 'react';
import { graphql, Link } from 'gatsby';
import { L2Hero, L2Library, Ram, ResponsiveContext } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  query {
    libraryFilterOptions {
      markets {
        id
        text
        value
      }
      regions {
        text
        value
      }
      services {
        id
        text
        value
      }
      types {
        text
        value
      }
    }
    prismic {
      allLibrarys {
        edges {
          node {
            hero_header
            hero_copy
            hero_image
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.allLibrarys.edges[0].node;
  const { markets, regions, services, types } = data.libraryFilterOptions;

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.hero_image && content.hero_image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <>
              <L2Hero
                backgroundURL={content.hero_image.url}
                htmlSerializer={htmlSerializer}
                size={size}
                titleHead={content.hero_header}
                titleText={content.hero_copy}
              />
              <L2Library
                Link={Link}
                size={size}
                markets={markets}
                regions={regions}
                services={services}
                types={types}
              />
              <Ram color="CB361D" />
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
